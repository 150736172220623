import axios from "@/utils/AxiosPag";

export class PagamentoService {
  async findAll(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/solicitacoes-pagamentos/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }

  async changeStatus(solicitacaoPagamentoId, status) {
  return  await axios
      .getInstance()
      .put(`/solicitacoes-pagamentos/${solicitacaoPagamentoId}/status`, {
        status,
      });
  }

  async findAllMetodosPagamento() {
    const response = await axios.getInstance().get(`/metodos-pagamento`);
    return response.data;
  }

  async create(pagamento) {
    const response = await axios.getInstance().post(`/pagamentos`, pagamento);
    return response.data;
  }
}
